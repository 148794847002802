import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/avatar';
import { prokeepLogoSvg } from '../../../svgs/index.tsx';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/avatar';

const userInitials = (name = '') => {
  if (name === null || name === 'null null' || name.startsWith('(')) {
    return '?';
  }

  const names = name.split(' ');

  const firstInit = names?.[0]?.charAt(0).toUpperCase() || '';
  const lastInit = names?.[1]?.charAt(0).toUpperCase() || '';

  return `${firstInit}${lastInit}`;
};

const sizes = {
  sm: {
    widthHeight: 22,
    fontSize: FONTSIZE_THEMES.AVATAR_SM,
    spanHeight: FONTSIZE_THEMES.AVATAR_SM,
    iconFontSize: FONTSIZE_THEMES.ICON_AVATAR_SM,
    presenceWidthHeight: 6,
    presenceBottomRight: 1,
  },
  md: {
    widthHeight: 30,
    fontSize: FONTSIZE_THEMES.AVATAR_MD,
    spanHeight: FONTSIZE_THEMES.AVATAR_MD,
    iconFontSize: FONTSIZE_THEMES.ICON_AVATAR_MD,
    presenceWidthHeight: 8,
    presenceBottomRight: 1,
  },
  lg: {
    widthHeight: 38,
    fontSize: FONTSIZE_THEMES.AVATAR_LG,
    spanHeight: FONTSIZE_THEMES.AVATAR_LG,
    iconFontSize: FONTSIZE_THEMES.ICON_AVATAR_LG,
    presenceWidthHeight: 9,
    presenceBottomRight: 2,
  },
};

const types = {
  external: {
    color: THEMES.EXTERNAL_TEXT,
    backgroundColor: THEMES.EXTERNAL_BACKGROUND,
    borderColor: THEMES.EXTERNAL_BORDER,
  },
  internal: {
    color: THEMES.INTERNAL_TEXT,
    backgroundColor: THEMES.INTERNAL_BACKGROUND,
    borderColor: THEMES.INTERNAL_BORDER,
  },
  bot: {
    color: THEMES.BOT_TEXT,
    backgroundColor: THEMES.BOT_BACKGROUND,
    borderColor: THEMES.BOT_BORDER,
  },
  threadUnread: {
    color: THEMES.EXTERNAL_TEXT,
    backgroundColor: THEMES.UNREAD_EXTERNAL_BACKGROUND,
    borderColor: THEMES.UNREAD_EXTERNAL_BORDER,
  },
  threadRead: {
    color: THEMES.EXTERNAL_TEXT,
    backgroundColor: THEMES.READ_EXTERNAL_BACKGROUND,
    borderColor: THEMES.READ_EXTERNAL_BORDER,
  },
  threadActive: {
    color: THEMES.EXTERNAL_TEXT,
    backgroundColor: THEMES.ACTIVE_EXTERNAL_BACKGROUND,
    borderColor: THEMES.ACTIVE_EXTERNAL_BORDER,
  },
  activityBoard: {
    color: THEMES.ACTIVITY_BOARD_TEXT,
    backgroundColor: THEMES.ACTIVITY_BOARD_BACKGROUND,
    borderColor: THEMES.ACTIVITY_BOARD_BORDER,
  },
};

const isIcon = (props) => props.icon || props.type === 'bot';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const AvatarStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props?.onClick ? 'pointer' : 'default')};
  font-family: 'Barlow', sans-serif;

  min-width: ${(props) => sizes[props.size].widthHeight}px;
  min-height: ${(props) => sizes[props.size].widthHeight}px;
  max-width: ${(props) => sizes[props.size].widthHeight}px;
  max-height: ${(props) => sizes[props.size].widthHeight}px;
  border-radius: 50%;

  font-size: ${(props) =>
    isIcon(props)
      ? sizes[props.size].iconFontSize
      : sizes[props.size].fontSize}px;
  line-height: ${(props) =>
    isIcon(props)
      ? sizes[props.size].iconFontSize
      : sizes[props.size].fontSize}px;
  font-weight: ${(props) => (isIcon(props) ? '500' : '600')};

  color: ${(props) => types[props.type].color};
  background-color: ${(props) => types[props.type].backgroundColor};
  border: 1px solid ${(props) => types[props.type].borderColor};
  opacity: ${(props) => (props.disabled ? '0.4' : 1)};

  & span {
    height: ${(props) =>
      isIcon(props)
        ? sizes[props.size].iconFontSize
        : sizes[props.size].spanHeight}px;
  }

  & svg {
    width: ${(props) => sizes[props.size].iconFontSize}px;
    height: ${(props) => sizes[props.size].iconFontSize}px;
  }
`;

const ImageAvatarStyle = styled.div`
  min-width: ${(props) => sizes[props.size].widthHeight}px;
  min-height: ${(props) => sizes[props.size].widthHeight}px;
  max-width: ${(props) => sizes[props.size].widthHeight}px;
  max-height: ${(props) => sizes[props.size].widthHeight}px;

  border-radius: 50%;
  background: #eee no-repeat center;

  background-size: cover;

  background-image: url('${(props) => props.avatarUrl}');

  border: 1px solid transparent;

  cursor: ${(props) => (props?.onClick ? 'pointer' : 'default')};
`;

const ActivityIndicator = styled.div`
  width: ${(props) => sizes[props.size].presenceWidthHeight}px;
  height: ${(props) => sizes[props.size].presenceWidthHeight}px;
  background-color: ${(props) =>
    props.active ? THEMES.PRESENCE_ACTIVE : THEMES.PRESENCE_INACTIVE};
  border-radius: 50%;
  position: absolute;
  right: ${(props) => sizes[props.size].presenceBottomRight}px;
  bottom: ${(props) => sizes[props.size].presenceBottomRight}px;
`;

export const supportedIcons = {
  tag: 'ri-list-check-2',
  contact: 'ri-contacts-book-2-line',
  company: 'ri-hotel-line',
  details: 'ri-draft-line',
  user: 'ri-user-line',
  userFill: 'ri-user-fill',
  smsChannel: 'ri-question-answer-line',
  emailChannel: 'ri-mail-line',
  faxChannel: 'ri-printer-line',
  anncChannel: 'ri-base-station-fill',
  webchatChannel: 'ri-message-3-line',
  webconnectChannel: 'ri-message-3-line',
  activityInPerson: 'ri-user-line',
  activityPhoneCall: 'ri-phone-line',
  activityVideoCall: 'ri-vidicon-line',
  activityOther: 'ri-health-book-line',
  settings: 'ri-settings-2-fill',
  label: 'ri-price-tag-3-line',
  task: 'ri-draft-line',
  template: 'ri-file-list-3-line',
};

const Avatar = ({
  type,
  size,
  children,
  avatarUrl,
  icon,
  hasCustomChildren,
  displayPresence,
  active,
  customContainerStyle,
  customActivityStyle,
  dataTestId,
  ...otherProps
}) => {
  let child = null;

  let mutableUrl = avatarUrl;
  if (icon === 'group')
    mutableUrl =
      'https://s3.us-west-2.amazonaws.com/prod.static.prokeep.com/avatars/group-160-01.png';

  if (icon === 'fsmChannel') mutableUrl = avatarUrl;

  if (!mutableUrl) {
    if (type === 'bot') {
      child = prokeepLogoSvg;
    } else if (icon) {
      child = <i className={supportedIcons?.[icon] || 'ri-question-mark'} />;
    } else if (hasCustomChildren) {
      child = children;
    } else {
      child = userInitials(children);
    }
  }

  return (
    <Wrapper
      customContainerStyle={customContainerStyle}
      data-testid={dataTestId}
      css={(props) => props?.customContainerStyle?.(props)}
    >
      {mutableUrl ? (
        <ImageAvatarStyle
          size={size}
          avatarUrl={mutableUrl}
          css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
          {...otherProps}
        />
      ) : (
        <AvatarStyle
          size={size}
          type={type}
          icon={!!icon}
          css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
          {...otherProps}
        >
          <span>{child}</span>
        </AvatarStyle>
      )}
      {displayPresence && type === 'internal' ? (
        <ActivityIndicator
          size={size}
          active={active}
          // borderColor={activityIconBorderColor}
          customActivityStyle={customActivityStyle}
          css={(props) => props?.customActivityStyle?.(props)}
          className="activity-indicator"
        />
      ) : null}
    </Wrapper>
  );
};

Avatar.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  hasCustomChildren: PropTypes.bool,
  displayPresence: PropTypes.bool,
  active: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customActivityStyle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  avatarUrl: PropTypes.string,
  dataTestId: PropTypes.string,
};

Avatar.defaultProps = {
  type: 'external',
  size: 'lg',
  icon: null,
  hasCustomChildren: false,
  displayPresence: false,
  active: false,
  customContainerStyle: null,
  customActivityStyle: null,
  children: '',
  avatarUrl: '',
  dataTestId: null,
};

// made this utility to be able to use when using <Avatar component to quickly deduce with icon to use
// without needing giant ternary statements within the `icon` prop. Continue to add to the if statement
// as necessary.
export const populateAvatarIcon = (iconConfig) => {
  if (iconConfig?.isGroup) return 'group';
  else if (iconConfig?.isSms) return 'smsChannel';
  else if (iconConfig?.isFax) return 'faxChannel';
  else if (iconConfig?.isEmail) return 'emailChannel';
  else if (iconConfig?.isWebchat) return 'webchatChannel';
  else if (iconConfig?.isWebconnect) return 'webconnectChannel';
  else if (iconConfig?.isUser) return 'user';
  else if (iconConfig?.isFsm) return 'fsmChannel';
  else if (iconConfig?.isSystem) return 'bot';

  return null;
};

export default Avatar;
